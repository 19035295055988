import 'swiper/swiper-bundle.css';
import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';

import '../css/load.scss';

import Swiper from 'swiper/bundle';

import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';

(function ($) {
	const $win = $(window);
	const $doc = $(document);
	const $loader = $('.app-loader');

	initHeaderMenuToggler();
	initSearchFromIcon();
	initSliderTestimony();
	initPhotoSwipe('.gallery');
	scrollToNewsletter();

	$win.on('load', () => {
		$('.map').each((i, elem) => {
			const data = JSON.parse(elem.dataset.map);

			initMap(elem, data, true);
		});
		$('.map-multi').each((i, elem) => {
			const data = JSON.parse(elem.dataset.map);

			initMap(elem, data, false);
		});
	});

	function addLoader() {
		if (!$loader.length) {
			return;
		}

		$loader.addClass('active');
	}

	function removeLoader() {
		if (!$loader.length) {
			return;
		}

		$loader.removeClass('active');
	}

	function initHeaderMenuToggler() {
		$('.header .header__menu-toggle').on('click', function () {
			$(this).closest('.header__container').toggleClass('menu-open');
		});
	}

	function initSearchFromIcon() {
		$('.search__btn').on('click', function (e) {
			e.preventDefault();
			$(this).closest('.search-form').toggleClass('active');
		});
	}

	function initSliderTestimony() {
		$('.slider--testimonials .swiper-container').each((i, slider) => {
			new Swiper(slider, {
				slidesPerView: 3,
				spaceBetween: 60,
				loop: true,
				autoplay: {
					delay: 2000,
					disableOnInteraction: false
				},
				breakpoints: {
					240: {
						slidesPerView: 1,
						spaceBetween: 30
					},
					413: {
						slidesPerView: 2,
						spaceBetween: 60
					},
					680: {
						slidesPerView: 3,
						spaceBetween: 60
					}
				}
			});
		});
	}

	function initPhotoSwipe(gallerySelector) {
		var parseThumbnailElements = function (el) {
			var thumbElements = el.childNodes,
				numNodes = thumbElements.length,
				items = [],
				figureEl,
				linkEl,
				size,
				item;

			for (var i = 0; i < numNodes; i++) {
				figureEl = thumbElements[i]; // <figure> element

				// include only element nodes
				if (figureEl.nodeType !== 1) {
					continue;
				}

				linkEl = figureEl.children[0]; // <a> element

				size = linkEl.getAttribute('data-size').split('x');

				// create slide object
				item = {
					src: linkEl.getAttribute('href'),
					w: parseInt(size[0], 10),
					h: parseInt(size[1], 10)
				};

				if (figureEl.children.length > 1) {
					// <figcaption> content
					item.title = figureEl.children[1].innerHTML;
				}

				if (linkEl.children.length > 0) {
					// <img> thumbnail element, retrieving thumbnail url
					item.msrc = linkEl.children[0].getAttribute('src');
				}

				item.el = figureEl; // save link to element for getThumbBoundsFn
				items.push(item);
			}

			return items;
		};

		// find nearest parent element
		var closest = function closest(el, fn) {
			return el && (fn(el) ? el : closest(el.parentNode, fn));
		};

		// triggers when user clicks on thumbnail
		var onThumbnailsClick = function (e) {
			e = e || window.event;
			e.preventDefault ? e.preventDefault() : (e.returnValue = false);

			var eTarget = e.target || e.srcElement;

			// find root element of slide
			var clickedListItem = closest(eTarget, function (el) {
				return el.tagName && el.tagName.toUpperCase() === 'FIGURE';
			});

			if (!clickedListItem) {
				return;
			}

			// find index of clicked item by looping through all child nodes
			// alternatively, you may define index via data- attribute
			var clickedGallery = clickedListItem.parentNode,
				childNodes = clickedListItem.parentNode.childNodes,
				numChildNodes = childNodes.length,
				nodeIndex = 0,
				index;

			for (var i = 0; i < numChildNodes; i++) {
				if (childNodes[i].nodeType !== 1) {
					continue;
				}

				if (childNodes[i] === clickedListItem) {
					index = nodeIndex;
					break;
				}
				nodeIndex++;
			}

			if (index >= 0) {
				// open PhotoSwipe if valid index found
				openPhotoSwipe(index, clickedGallery);
			}
			return false;
		};

		// parse picture index and gallery index from URL (#&pid=1&gid=2)
		var photoswipeParseHash = function () {
			var hash = window.location.hash.substring(1),
				params = {};

			if (hash.length < 5) {
				return params;
			}

			var vars = hash.split('&');
			for (var i = 0; i < vars.length; i++) {
				if (!vars[i]) {
					continue;
				}
				var pair = vars[i].split('=');
				if (pair.length < 2) {
					continue;
				}
				params[pair[0]] = pair[1];
			}

			if (params.gid) {
				params.gid = parseInt(params.gid, 10);
			}

			return params;
		};

		var openPhotoSwipe = function (index, galleryElement, disableAnimation, fromURL) {
			var pswpElement = document.querySelectorAll('.pswp')[0],
				gallery,
				options,
				items;

			items = parseThumbnailElements(galleryElement);

			// define options (if needed)
			options = {
				// define gallery index (for URL)
				galleryUID: galleryElement.getAttribute('data-pswp-uid'),

				getThumbBoundsFn: function (index) {
					// See Options -> getThumbBoundsFn section of documentation for more info
					var thumbnail = items[index].el.getElementsByTagName('img')[0], // find thumbnail
						pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
						rect = thumbnail.getBoundingClientRect();

					return { x: rect.left, y: rect.top + pageYScroll, w: rect.width };
				}
			};

			// PhotoSwipe opened from URL
			if (fromURL) {
				if (options.galleryPIDs) {
					// parse real index when custom PIDs are used
					// http://photoswipe.com/documentation/faq.html#custom-pid-in-url
					for (var j = 0; j < items.length; j++) {
						if (items[j].pid == index) {
							options.index = j;
							break;
						}
					}
				} else {
					// in URL indexes start from 1
					options.index = parseInt(index, 10) - 1;
				}
			} else {
				options.index = parseInt(index, 10);
			}

			// exit if index not found
			if (isNaN(options.index)) {
				return;
			}

			if (disableAnimation) {
				options.showAnimationDuration = 0;
			}

			// Pass data to PhotoSwipe and initialize it
			gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
			gallery.init();
		};

		// loop through all gallery elements and bind events
		var galleryElements = document.querySelectorAll(gallerySelector);

		for (var i = 0, l = galleryElements.length; i < l; i++) {
			galleryElements[i].setAttribute('data-pswp-uid', i + 1);
			galleryElements[i].onclick = onThumbnailsClick;
		}

		// Parse URL and open gallery if it contains #&pid=3&gid=1
		var hashData = photoswipeParseHash();
		if (hashData.pid && hashData.gid) {
			openPhotoSwipe(hashData.pid, galleryElements[hashData.gid - 1], true, true);
		}
	}

	// init the location map
	function initMap(elem, data, singleLocation = true) {
		const mapStyles = [
			{
				elementType: 'geometry',
				stylers: [
					{
						color: '#ebe3cd'
					}
				]
			},
			{
				elementType: 'labels.text.fill',
				stylers: [
					{
						color: '#523735'
					}
				]
			},
			{
				elementType: 'labels.text.stroke',
				stylers: [
					{
						color: '#f5f1e6'
					}
				]
			},
			{
				featureType: 'administrative',
				elementType: 'geometry.stroke',
				stylers: [
					{
						color: '#c9b2a6'
					}
				]
			},
			{
				featureType: 'administrative.land_parcel',
				elementType: 'geometry.stroke',
				stylers: [
					{
						color: '#dcd2be'
					}
				]
			},
			{
				featureType: 'administrative.land_parcel',
				elementType: 'labels',
				stylers: [
					{
						visibility: 'off'
					}
				]
			},
			{
				featureType: 'administrative.land_parcel',
				elementType: 'labels.text.fill',
				stylers: [
					{
						color: '#ae9e90'
					}
				]
			},
			{
				featureType: 'landscape.natural',
				elementType: 'geometry',
				stylers: [
					{
						color: '#dfd2ae'
					}
				]
			},
			{
				featureType: 'poi',
				elementType: 'geometry',
				stylers: [
					{
						color: '#dfd2ae'
					}
				]
			},
			{
				featureType: 'poi',
				elementType: 'labels.text',
				stylers: [
					{
						visibility: 'off'
					}
				]
			},
			{
				featureType: 'poi',
				elementType: 'labels.text.fill',
				stylers: [
					{
						color: '#93817c'
					}
				]
			},
			{
				featureType: 'poi.park',
				elementType: 'geometry.fill',
				stylers: [
					{
						color: '#a5b076'
					}
				]
			},
			{
				featureType: 'poi.park',
				elementType: 'labels.text.fill',
				stylers: [
					{
						color: '#447530'
					}
				]
			},
			{
				featureType: 'road',
				stylers: [
					{
						visibility: 'off'
					}
				]
			},
			{
				featureType: 'road',
				elementType: 'geometry',
				stylers: [
					{
						color: '#f5f1e6'
					}
				]
			},
			{
				featureType: 'road.arterial',
				elementType: 'geometry',
				stylers: [
					{
						color: '#fdfcf8'
					}
				]
			},
			{
				featureType: 'road.highway',
				elementType: 'geometry',
				stylers: [
					{
						color: '#f8c967'
					}
				]
			},
			{
				featureType: 'road.highway',
				elementType: 'geometry.stroke',
				stylers: [
					{
						color: '#e9bc62'
					}
				]
			},
			{
				featureType: 'road.highway.controlled_access',
				elementType: 'geometry',
				stylers: [
					{
						color: '#e98d58'
					}
				]
			},
			{
				featureType: 'road.highway.controlled_access',
				elementType: 'geometry.stroke',
				stylers: [
					{
						color: '#db8555'
					}
				]
			},
			{
				featureType: 'road.local',
				elementType: 'labels',
				stylers: [
					{
						visibility: 'off'
					}
				]
			},
			{
				featureType: 'road.local',
				elementType: 'labels.text.fill',
				stylers: [
					{
						color: '#806b63'
					}
				]
			},
			{
				featureType: 'transit.line',
				elementType: 'geometry',
				stylers: [
					{
						color: '#dfd2ae'
					}
				]
			},
			{
				featureType: 'transit.line',
				elementType: 'labels.text.fill',
				stylers: [
					{
						color: '#8f7d77'
					}
				]
			},
			{
				featureType: 'transit.line',
				elementType: 'labels.text.stroke',
				stylers: [
					{
						color: '#ebe3cd'
					}
				]
			},
			{
				featureType: 'transit.station',
				elementType: 'geometry',
				stylers: [
					{
						color: '#dfd2ae'
					}
				]
			},
			{
				featureType: 'water',
				elementType: 'geometry.fill',
				stylers: [
					{
						color: '#b9d3c2'
					}
				]
			},
			{
				featureType: 'water',
				elementType: 'labels.text.fill',
				stylers: [
					{
						color: '#92998d'
					}
				]
			}
		];
		const map = new google.maps.Map(elem, {
			styles: mapStyles,
			disableDefaultUI: true,
			...data.map
		});

		if (singleLocation === true) {
			const marker = new google.maps.Marker({
				map: map,
				...data.marker
			});
		} else {
			const markers = appData.markers;
			const infoWindow = new google.maps.InfoWindow();
			markers.forEach(([position, title, permalink], i) => {
				position.lat = +position.lat;
				position.lng = +position.lng;

				const marker = new google.maps.Marker({
					position,
					map,
					title: `<a class="btn" href="${permalink}" target="_blank">${title}</a>`,
					optimized: false
				});

				// Add a click listener for each marker, and set up the info window.
				marker.addListener('click', () => {
					infoWindow.close();
					infoWindow.setContent(marker.getTitle());
					infoWindow.open(marker.getMap(), marker);
				});
			});
		}
	}

	function scrollToNewsletter() {
		$('.article__call a').on('click', function (e) {
			e.preventDefault();

			$('html, body').animate(
				{
					scrollTop: $('.form-light').offset().top
				},
				1000
			);
			$('.tnp-name').focus();
			$('.form-light').addClass('scrolled-to');
		});
	}
})(jQuery);
